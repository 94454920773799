<template>
  <div class="home_box">
    <el-container class="container">
      <el-aside class="aside" width="230px">
        <div>
          <img class="logo_img" src="https://cargocnwebimage.oss-cn-beijing.aliyuncs.com/hhypc/hhy_logo1.png" alt="">
        </div>

        <el-menu :default-active="activeIndex" :unique-opened="true" v-for="(item,index) in menulist" :key="index" @open="handleOpen" @close="handleClose" @select="handSelect" background-color="#545c64" text-color="#ffffff" active-text-color="#ffd04b">
          <el-submenu :index="item.code">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>{{item.name}}</span>
            </template>
            <el-menu-item-group v-for="(value,sign) in item.children" :key="sign">
              <el-menu-item :index="value.code" @click="addTab(value,index)">{{value.name}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>

      </el-aside>

      <el-container class="right_box">
        <div class="right_content">
          <el-header class="header_box">

            <div class="user_inform">
              <div class="dropdown_box">
                <el-dropdown trigger="click" size="mini">
                  <span class="username">系统管理员 <i class="el-icon-caret-bottom" style="color: #333"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item>修改密码</el-dropdown-item>
                    <el-dropdown-item>切换账户</el-dropdown-item> -->
                    <el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <div class="tabs_box">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick" @tab-remove="removeTab">
                  <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.name" :name="item.name" :closable="index>0"></el-tab-pane>
                </el-tabs>
              </div>

            </div>
          </el-header>
          <el-main class="right_main">
            <div class="scroll">
              <router-view></router-view>
            </div>
          </el-main>
        </div>

      </el-container>
    </el-container>

  </div>
</template>

<script>
export default {
  data() {
    return {
      menulist: [],
      activeIndex: "", //打开的导航栏
      activeName: "", //选中的tabs
      tabList: [],
    };
  },
  activated() {
    var that = this;
    var menu = that.$store.state.menu;
    that.activeIndex = menu.activeIndex ?menu.activeIndex :  that.activeIndex ;
    that.activeName = menu.activeName ? menu.activeName: that.activeName;
    console.log(that.activeIndex, "activated",that.activeName );
    console.log(that.$store.state.menu.activeIndex);
  },
  created() {
    var that = this;
    var routing = this.$store.state.menu;
    console.log(routing, "routing");
    that.menulist = routing.menuList; //侧边导航栏

    if (routing.tabs && routing.tabs.length > 0) {
      that.tabList = routing.tabs;
      that.activeName = that.$route.name;
    } else {
      let obj = {};
      console.log(that.$route.path, that.$route.name);
      obj.path = that.$route.path;
      obj.name = that.$route.name;
      that.activeName = that.$route.name;
      that.tabList.push(obj);
    }
  },
  updated() {
    let that = this;
    var routing = this.$store.state.menu;
    console.log(routing, "routing");
    that.menulist = routing.menuList; //侧边导航栏
     that.activeIndex = routing.activeIndex ?routing.activeIndex :  that.activeIndex ;
    that.activeName = routing.activeName ? routing.activeName: that.activeName;
  },
  methods: {
    handleClick() {
      console.log(this.$store.state.menu.activeIndex);
      console.log(this.activeName, "this.activeName");
      console.log(this.activeIndex, "activeIndex");
      var that = this;
      let routePath = that.tabList.find((item) => {
        return item.name == this.activeName;
      });
      console.log(routePath, "routePath");
      this.$store.commit("activeIndex", routePath.code);
      this.$store.commit("activeName", routePath.name);
      this.$router.push({
        path: routePath.path,
      });

      that.activeIndex = this.$store.state.menu.activeIndex;
      that.activeName = this.$store.state.menu.activeName;
      console.log(this.activeIndex, "activeIndex222");
    },
    //删除tabs
    removeTab(tab) {
      var list = this.tabList;
      list.forEach((val, index) => {
        console.log(val, index);
        if (val.name == tab) {
          let nextTab = list[index + 1] || list[index - 1];
          if (nextTab) {
            this.activeName = nextTab.name;
          }
          this.removeEvent(index, val);
        }
      });
    },

    //删除tabs方法
    removeEvent(index, path) {
      console.log(index, path);
      this.tabList.splice(index, 1);
      this.$store.commit("tabs", this.tabList);
      this.tabList.forEach((val) => {
        if (val.name == this.activeName) {
          this.$router.push({
            path: val.path,
          });
        }
      });
    },

    logOut() {
      console.log("退出登录");
      var keys = Object.keys(localStorage);
      keys.forEach(atem => {
        if (atem != "loginUserName" && atem != "loginPassward") {
          localStorage.removeItem(atem);
        }
      })
      sessionStorage.clear();
      this.$router.push({
        path: "/login",
      });
    },

    addTab(items, index) {
      console.log(items, index);
      //查重  当前选中的导航里面 如果存在tab里面就不push
      let router = this.tabList.find((item) => {
        return item.name == items.name;
      });
      console.log(router, "router");
      if (!router) {
        this.tabList.push(items);
      }
      this.activeName = items.name;
      this.$store.commit("activeIndex", items.code);
      this.$store.commit("activeName", items.name);
      this.$store.commit("tabs", this.tabList);

      this.$router.push(items.path);
    },

    handleOpen(key, keyPath) {
      // console.log("打开", key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log("关闭", key, keyPath);
    },
    handSelect(key, keyPath) {
      console.log("选择", key, keyPath);
      this.activeIndex = key;
      localStorage.setItem("activeIndex", key);
    },
  },
};
</script>

<style lang="scss" scoped>
.home_box {
  height: 100%;
  width: 100%;

  .container {
    height: 100%;
    display: flex;

    .aside {
      background-color: rgb(84, 92, 100);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      .logo_img {
        width: 95px;
        height: 50px;
      }

      .title {
        font-size: 16px;
      }
      .el-menu {
        border-right: none !important;
      }
    }

    .right_box {
      display: flex;
      justify-content: flex-end;
      .right_content {
        .right_main {
          width: calc(100vw - 230px) !important;
          height: calc(100% - 80px) !important ;

          .scroll {
            height: 100%;
            overflow-y: scroll;
            // overflow-x: scroll;
            // overflow: hidden;
            // position: relative;
          }
        }
      }

      .header_box {
        width: calc(100vw - 230px) !important;
        height: 80px !important;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

        .user_inform {
          height: 80px;
          // display: flex;
          // align-items: center;
          // justify-content: flex-end;
          .dropdown_box {
            height: 40px;
            text-align: right;
            line-height: 40px;
          }

          .tabs_box {
            /deep/.el-tabs__item {
              padding: 0px 15px !important;
              height: 35px !important;
              line-height: 35px !important;
              border: none;
              font-size: 12px;
              color: #909399;
              // border-bottom: none;
            }

            /deep/.el-tabs--card > .el-tabs__header .el-tabs__item:before {
              content: " ";
              position: absolute;
              display: inline-block;
              height: 23px;
              width: 1px;
              left: 0px;
              right: 0px;
              top: 0px;
              bottom: 0px;
              margin: auto 0;
              background: #dfdfdf;
            }

            /deep/.el-tabs--card > .el-tabs__header {
              // border-bottom: none;
              border: none;
              font-size: 12px;
              color: #909399;
            }

            /deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
              border: none;
              margin-left: -1px;
            }

            /deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
              color: #086ffd;
            }
          }
        }
      }
    }
  }
}
</style>
import Vue from "vue";

import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import home from "../pages/home.vue";
import welcome from "../pages/welcome.vue";
const routes = [
  {
    path: "/",
    redirect: "/login",
    hidden: true,
  },
  {
    path: "/login",
    redirect: "",
    hidden: true,
    component: (resolve) => require(["../pages/login"], resolve),
  },
  {
    path: "/home",
    component: home,
    redirect: "/welcome",
    hidden: true,
    children: [
      {
        path: "/welcome",
        name: "欢迎页",
        component: welcome,
        hidden: true,
      },
    ],
  },


  {
    path: "",
    component: home,
    name: "基础数据",
    iconCls: "el-icon-message",
    children: [
      {
        path: "/carrier/carrier",
        name: "承运商管理",
        component: (resolve) => require(["../pages/carrier/carrier"], resolve),
        meta: { keepAlive: true,  name: '/carrier/carrier' }
      },
      {
        path: "/carrier/addCarrier",
        name: "承运商管理新建",
        component: (resolve) => require(["../pages/carrier/addCarrier"], resolve),
      },
      {
        path: "/line/line",
        name: "线路管理",
        component: (resolve) => require(["../pages/line/line"], resolve),
        meta: { keepAlive: true, name: '/line/line' }
      }, 
      {
        path: "/line/addLine",
        name: "线路管理新建",
        component: (resolve) => require(["../pages/line/addLine"], resolve),
      },
      {
        path: "/shipper/shipper",
        name: "发货人管理",
        component: (resolve) => require(["../pages/shipper/shipper"], resolve),
        meta: { keepAlive: true, name: '/shipper/shipper' }
      }, 
      {
        path: "/shipper/shipperDetails",
        name: "托运人管理详情",
        component: (resolve) =>
          require(["../pages/shipper/shipperDetails"], resolve),
      },
    ],
  },

  {
    path: "",
    component: home,
    name: "运输管理",
    iconCls: "el-icon-message",
    children: [
      {
        path: "/order/order",
        name: "订单管理",
        component: (resolve) => require(["../pages/order/order"], resolve),
        meta: { keepAlive: true, name: '/order/order' }
      },
      {
        path: "/order/orderDetails",
        name: "订单详情",
        component: (resolve) => require(["../pages/order/orderDetails"], resolve),
      },
    ],
  },

];

const router = new VueRouter({
  routes,
});

export default router;

import Vue from "vue";
import App from "./App.vue";
import Router from "vue-router";
import router from "./router/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/globlcss.css";
import store from "./store";

Vue.use(ElementUI);
Vue.use(Router);

Vue.config.productionTip = false;

// if (sessionStorage.getItem("store")) {
//   store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store"))))
// }
// //在页面刷新时将vuex里的信息保存到sessionStorage里
// window.onbeforeunload = function() {
//   sessionStorage.setItem("store", JSON.stringify(store.state))
// }

if (sessionStorage.getItem("store")) {
  store.replaceState(
      Object.assign(
          {},
          store.state,
          JSON.parse(sessionStorage.getItem("store"))
      )
  );
  sessionStorage.removeItem("store")
}

//监听，在页面刷新时将vuex里的信息保存到sessionStorage里
window.addEventListener("beforeunload", () => {
  sessionStorage.setItem("store", JSON.stringify(store.state));
});

new Vue({
  el: "#app",
  router,
  store: store,
  render: (h) => h(App),
});

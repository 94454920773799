let tabsList = JSON.parse(sessionStorage.getItem("tabs"))
console.log(tabsList);
const state = {
    userInfo: {},  //用户登录信息
    menuList: [],   //侧边栏
    tabs: tabsList || [],
    activeIndex: '',
    activeName:'',
};
// console.log(sessionStorage.getItem("tabs"));
const mutations = {
    login(state, user) {
        state.userInfo = user;
    },
    menuEvent(state, menu) {
        state.menuList = menu;
    },
    tabs(state, tabs) {
        console.log(tabs, 'tabs');
        sessionStorage.setItem("tabs",JSON.stringify(tabs))
        state.tabs = tabs;        
    },
    activeIndex(state, activeIndex) {
        state.activeIndex = activeIndex
    },
    activeName(state, activeName) {
        state.activeName = activeName
    }

};

const actions = {
    updateTabs(context,value){
      context.commit('tabs',value)
    },
}
  
export default {
    state,
    mutations,
    actions
}
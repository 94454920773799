<template>
  <div class="welcome">
    <div class="title">欢迎进入汉鸭子®</div>
    <img class="img" src="https://cargocnwebimage.oss-cn-beijing.aliyuncs.com/hhypc/hhywelcome1.png" />
  </div>
</template>


<script>
export default {
  data() {
    return {
      imageList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      options: [],
      value: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.welcome {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 60px;
    font-weight: bold;
    color: #0070b1;
    font-style: italic;
  }
    .img {
      width: 45%;
    }
}
</style>
